.offersection {
    padding: 10rem 0;
    min-height: 100vh;
}
.offersection a ,.offersection a:hover,.offersection a:focus,.offersection a:active {
    text-decoration: none;
    outline: none;
}
.offersection ul {
    margin: 0;
    padding: 0;
    list-style: none;
}.bg-gray {
    background-color: #f9f9f9;
}

.offersection .site-heading h2 {
  display: block;
  font-weight: 700;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.offersection .site-heading h2 span {
  color: var(--secondary-color);
}

.offersection .site-heading h4 {
  display: inline-block;
  padding-bottom: 20px;
  position: relative;
  text-transform: capitalize;
  z-index: 1;
}

.offersection .site-heading h4::before {
  background: var(--secondary-color) none repeat scroll 0 0;
  bottom: 0;
  content: "";
  height: 2px;
  left: 50%;
  margin-left: -25px;
  position: absolute;
  width: 50px;
}

.offersection .site-heading {
  margin-bottom: 60px;
  overflow: hidden;
  margin-top: -5px;
}

.carousel-shadow .owl-stage-outer {
  margin: -15px -15px 0;
  padding: 15px;
}

.we-offer-area .our-offer-carousel .owl-dots .owl-dot span {
  background: #ffffff none repeat scroll 0 0;
  border: 2px solid;
  height: 15px;
  margin: 0 5px;
  width: 15px;
}

.we-offer-area .our-offer-carousel .owl-dots .owl-dot.active span {
  background: var(--secondary-color) none repeat scroll 0 0;
  border-color: var(--secondary-color);
}

.we-offer-area .o-item {
  background: #ffffff none repeat scroll 0 0;
  border-left: 2px solid var(--secondary-color);
  -moz-box-shadow: 0 0 10px #cccccc;
  -webkit-box-shadow: 0 0 10px #cccccc;
  -o-box-shadow: 0 0 10px #cccccc;
  box-shadow: 0 0 10px #cccccc;
  overflow: hidden;
  padding: 30px;
  position: relative;
  z-index: 1;
}

.we-offer-area.text-center .o-item {
  background: #ffffff none repeat scroll 0 0;
  border: medium none;
  padding: 67px 40px 64px;
}

.we-offer-area.text-center .o-item i {
  background: var(--secondary-color) none repeat scroll 0 0;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  color: #ffffff;
  font-size: 40px;
  height: 80px;
  line-height: 80px;
  position: relative;
  text-align: center;
  width: 80px;
  z-index: 1;
  transition: all 0.35s ease-in-out;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  margin-bottom: 25px;
}

.we-offer-area.text-center .o-item i::after {
  border: 2px solid var(--secondary-color);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  content: "";
  height: 90px;
  left: -5px;
  position: absolute;
  top: -5px;
  width: 90px;
  z-index: -1;
  transition: all 0.35s ease-in-out;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
}

.we-offer-area.o-item-border-less .o-item {
  border: medium none;
}

.we-offer-area .our-offer-o-items.less-carousel .equal-height {
  margin-bottom: 30px;
}

.we-offer-area.o-item-border-less .o-item .number {
  font-family: "Poppins",sans-serif;
  font-size: 50px;
  font-weight: 900;
  opacity: 0.1;
  position: absolute;
  right: 30px;
  top: 30px;
}

.our-offer-carousel.center-active .owl-o-item:nth-child(2n) .o-item,
.we-offer-area.center-active .single-o-item:nth-child(2n) .o-item {
  background: var(--secondary-color) none repeat scroll 0 0;
}

.our-offer-carousel.center-active .owl-o-item:nth-child(2n) .o-item i,
.our-offer-carousel.center-active .owl-o-item:nth-child(2n) .o-item h4,
.our-offer-carousel.center-active .owl-o-item:nth-child(2n) .o-item p,
.we-offer-area.center-active .single-o-item:nth-child(2n) .o-item i,
.we-offer-area.center-active .single-o-item:nth-child(2n) .o-item h4,
.we-offer-area.center-active .single-o-item:nth-child(2n) .o-item p {
  color: #ffffff;
}

.we-offer-area .o-item i {
  color: var(--secondary-color);
  display: inline-block;
  font-size: 60px;
  margin-bottom: 20px;
}

.we-offer-area .o-item h4 {
  font-weight: 600;
  text-transform: capitalize;
}

.we-offer-area .o-item p {
  margin: 0;
}

.we-offer-area .o-item i,
.we-offer-area .o-item h4,
.we-offer-area .o-item p {
  transition: all 0.35s ease-in-out;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
}

.we-offer-area .o-item::after {
  background: var(--secondary-color) none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: -100%;
  position: absolute;
  top: 0;
  transition: all 0.35s ease-in-out;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  width: 100%;
  z-index: -1;
}

.we-offer-area .o-item:hover::after {
  left: 0;
}

.we-offer-area .o-item:hover i,
.we-offer-area .o-item:hover h4,
.we-offer-area .o-item:hover p {
  color: #ffffff !important;
}

.we-offer-area.text-center .o-item:hover i::after {
  border-color: #ffffff !important;
}

.we-offer-area.text-center .o-item:hover i {
  background-color: #ffffff !important;
  color: var(--secondary-color) !important;
}

.we-offer-area.text-left .o-item i {
  background: var(--secondary-color) none repeat scroll 0 0;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  color: #ffffff;
  display: inline-block;
  font-size: 60px;
  height: 100px;
  line-height: 100px;
  margin-bottom: 30px;
  position: relative;
  width: 100px;
  z-index: 1;
  text-align: center;
}

.we-offer-area.text-left .o-item i::after {
  border: 2px solid var(--secondary-color);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  content: "";
  height: 120px;
  left: -10px;
  position: absolute;
  top: -10px;
  width: 120px;
}