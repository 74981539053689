.sticky-top {
    top: -150px;
    transition: .5s;
}

.dropdown-menu{
    border: none;
}
.navbar-collapse{
    z-index: 999;
    background-color: white;
    border-radius: 8px;
}

.navbar {
    padding: 11px 0 !important;
    height: 75px;
}

.navbar .navbar-nav .nav-link {
    margin-right: 35px;
    padding: 0;
    color:#031d27;
    outline: none;
}

.navbar .navbar-nav .nav-link:hover,
.navbar .navbar-nav .nav-link.active {
    color: var(--secondary-color);
}

.logoimg{
    width: 280px;
}


@media (max-width: 991.98px) {
    .navbar .navbar-nav {
        padding: 0 15px;
        background: var(--primary);
    }

    .navbar .navbar-nav .nav-link  {
        margin-right: 0;
        padding: 10px 0;
    }
    .logoimg {
        width: 170px;
    }
}

@media (min-width: 992px) {
    .navbar .nav-item .dropdown-menu {
        display: block;
        padding: 1rem;
        margin-top: 0;
        top: 150%;
        opacity: 0;
        visibility: hidden;
        transition: .5s;
        border: none !important;
    }

    .navbar .nav-item:hover .dropdown-menu {
        top: 100%;
        visibility: visible;
        transition: .5s;
        opacity: 1;
    }
   
}