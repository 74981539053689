

/*.features-container {
  display: flex;
  height: 100vh;
}

.features-section {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  color: #fff;
  transition: flex .4s ease;
  position: relative;
}

/*.features-section .overlay {
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  position: absolute;
  transition: background-color .8s ease;
}

.features-section .content {
  z-index: 2;
}

/*.features-section:hover {
  flex: 2;
}

.features-section:hover .overlay {
  background-color: rgba(0, 0, 0, 0.95);
}

#marketing {
  background-image: url(https://images.unsplash.com/photo-1522205987242-8e22924ab42a?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=c0f679eb8f15705d46ea90008f39642b&auto=format&fit=crop&w=500&q=60);
}


#technology {
  background-image: url(https://images.unsplash.com/photo-1530893609608-32a9af3aa95c?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=fe6b92f88f55824e64a1bae15f5bf52a&auto=format&fit=crop&w=500&q=60);
}

#advertising {
  background-image: url(https://images.unsplash.com/photo-1517799094725-e3453440724e?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=353f3663a9fae75773d2942aeb37c2c8&auto=format&fit=crop&w=500&q=60);
}*/
.features-container {
  display: flex;
  height: 100vh;
}

.features-section {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  color: #fff;
  position: relative;
  transition: flex .4s ease;
}

.features-section .overlay {
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  position: absolute;
  transition: background-color 0.8s ease;
}

.features-section .content {
  z-index: 2;
  padding: 2rem 5rem;
  transition: width 0.3s ease;
}
.features-section .content li{
  margin-bottom: 5px;
  margin-top: 5px;
}

.features-section .content h1{
  transition: width 0.3s ease;
}

.features-section:hover {
  flex: 2;
}

.features-section:hover .overlay {
  background-color: rgba(0, 0, 0, 0.95);
}

ul {
  list-style: none;
}
.features-section .content ul{
  padding-left: 0;
}

/*li::before {
  content: "\f054";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  margin-right: 8px;
  color: #007BFF; /* Change the color if needed 
}*/

#feature1 {
  background-image: url('../assets/dry\ cleaning.jpg');
}

#feature2 {
  background-image: url('../assets/saree.jpg');
}

#feature3 {
  background-image: url('../assets/sofa.jpg');
}

#feature4 {
  background-image: url('../assets/wedding.jpg');
}

@media screen and (min-width: 280px) and  (max-width: 580px){
  .features-container{
flex-direction: column;
  }
  .features-section:hover, .features-section:active{
    flex:8 2 !important;
  }
  .features-container{
    height: auto;
  }
 
}
