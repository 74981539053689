
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap');


:root {
  --primary-color: #031d27;
  --secondary-color: #00c0de;
  --font-family: "Poppins", sans-serif;
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html{
  scroll-behavior: smooth;
  height: 100%;
  overflow-x: hidden;
}

body{
  overflow-x: hidden;
}

body,button{
  font-family: var(--font-family);
}

::-webkit-scrollbar{
  background-color: var(--primary-color);
  width: 0.3rem;
}

::-webkit-scrollbar-thumb{
  background-color: var(--secondary-color);
}


@media screen and (min-width:240px) and (max-width:1080px) {
  body::-webkit-scrollbar{
    display: none;
  }
}