/*.container{
    position: absolute;
    left:50%;
    top:50%;
    transform: translate(-50%,-50%);
    width:1000px;
    height:600px;
    padding:50px;
    background-color: #f5f5f5;
    box-shadow: 0 30px 50px #dbdbdb;
}*/
#slide{
    width:max-content;
    
}
.item{
    width:200px;
    height:300px;
    background-position: 50% 50%;
    display: inline-block;
    transition: 0.5s;
    background-size: cover;
    position: absolute;
    z-index: 1;
    top:63%;
    transform: translate(0,-50%);
    border-radius: 20px;
    box-shadow:  0 30px 50px #505050;
}


.item:nth-child(1),
.item:nth-child(2){
    left:0;
    top:0;
    transform: translate(0,0);
    border-radius: 0;
    width:100%;
    height:100%;
    box-shadow: none;
    
}
.item:nth-child(3){
    left:62.5%;
    
    
}
.item:nth-child(4){
    left:calc(50% + 440px);
    
}
.item:nth-child(5){
    left:calc(50% + 60px);
    display: none;
}
.item:nth-child(n+6){
    left:calc(50% + 880px);
    opacity: 0;
    
    
}
.item .content{
    position: absolute;
    top:63%;
    left:100px;
    width:500px;
    text-align: left;
    padding:0;
    color:#eee;
    transform: translate(0,-50%);
    display: none;
    font-family: system-ui;
    background-color: #000000c4;
    padding: 3rem;
}
.item:nth-child(2) .content{
    display: block;
    z-index: 11111;
   
}
.item .name{
    font-size: 40px;
    font-weight: bold;
    opacity: 0;
    animation:showcontent 1s ease-in-out 1 forwards
}
.item .des{
    margin:20px 0;
    opacity: 0;
    animation:showcontent 1s ease-in-out 0.3s 1 forwards
}
.item button{
    padding:10px 20px;
    border:none;
    opacity: 0;
    animation:showcontent 1s ease-in-out 0.6s 1 forwards
}
@keyframes showcontent{
    from{
        opacity: 0;
        transform: translate(0,100px);
        filter:blur(33px);
    }to{
        opacity: 1;
        transform: translate(0,0);
        filter:blur(0);
    }
}
.buttons{
    position: absolute;
    bottom:18px;
    z-index: 222222;
    text-align: center;
    width:100%;
    
}
.buttons button{
    width:50px;
    height:50px;
    border-radius: 50%;
    border:1px solid #555;
    transition: 0.5s;
    margin: 0 10px;
    
}
.content button{
    pointer-events: stroke;
}
.buttons button:hover{
    background-color: var(--secondary-color);
}

@media screen and (min-width: 280px) and  (max-width: 580px){
    .item:nth-child(3){
        display: none;
    }
    .item .content{
        top: 44%;
        width: auto;
        margin: 0 2rem;
        left: 0;
    }
    .info{
        display: none;
    }

}


 
  


  
 