.hero-header {
    
    background: url(coloured.jpg) center center no-repeat;
    background-size: cover;
}

.about-img {
    position: relative;
    overflow: hidden;
}

.about-features{
    background-color: #031d27;
    padding-right: 6rem;
    padding-left: 6rem;
}
.about-img::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
   /*background: url(../assets/bag.jpg) top left no-repeat;*/
    background-size: contain;
}
.hero-header .breadcrumb-item+.breadcrumb-item::before {
    color: var(--light);
}

.service-item {
    position: relative;
    padding: 45px 30px;
    background: #031d27;
    color: #f8f8f8;
    transition: .5s;
}

.globalbutton{
    background: #00c0de;
    border: none;
    color: #fff;
    font: inherit;
    line-height: 1;
    margin: 0.5em;
    padding: 1em 2em;
}
.service-item:hover {
    background: var(--secondary-color);
}

.services-boxes{
    background-color: #f8f8f8;
    padding-top: 8rem;
    padding-bottom: 8rem;
}
.up{
    
          
        background: #00c0de;
        border: none;
        color: #fff;
        font: inherit;
        line-height: 1;
        margin-top: 1em;
        padding: 1em 2em;
        text-decoration: none;
    
    
}
.service-item .service-icon {
    margin: 0 auto 20px auto;
    width: 90px;
    height: 90px;
    color: var(--secondary-color);
    background: var(--light);
    transition: .5s;
}

.service-item:hover .service-icon {
   /* background: #FFFFFF; */
}

.service-item h5,
.service-item p {
    transition: .5s;
}

.service-item:hover h5,
.service-item:hover p {
    color: #FFFFFF;
}

.service-item a.btn {
    position: relative;
    display: flex;
    color: var(--secondary-color);
    transition: .5s;
    z-index: 1;
}

.service-item:hover a.btn {
    color: #ffffff;
}

.service-item a.btn::before {
    position: absolute;
    content: "";
    width: 35px;
    height: 35px;
    top: 0;
    left: 0;
    border-radius: 35px;
    background: var(--light);
    transition: .5s;
    z-index: -1;
}

.service-item:hover a.btn::before {
    width: 100%;
    background: var(--light);
}

.accordion .accordion-item {
    border: none;
    margin-bottom: 15px;
}

.accordion .accordion-button {
    background: var(--light);
    border-radius: 2px;
}

.accordion .accordion-button:not(.collapsed) {
    color: #FFFFFF;
    background: var(--secondary-color);
    box-shadow: none;
}

.accordion .accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.accordion .accordion-body {
    padding: 15px   ;
}

